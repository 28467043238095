export default {
  banner: {
    titleImg: require('../images/business/banner_title.png'),
    video:{
      src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/171f2afc-17920c5f65f-0005-502e-0e5-a9c95.mp4',
      poster: require('../images/public/poster/business.jpg'),
      title: '',
      dec: '如何让你的100个客户秒变1000个？如何让一群人帮你一起拓客？单靠传统的异业联盟模式已经行不通了。共享商圈可以帮你解决持续的拓客问题，要集结整个商圈的资源，模式加系统才是完整解决方案。'
    }
  },
  cards:[
    {
      title: '可以改成如何让门店快速提升拓客速度？',
      content: '客源是每个门店的生命线。如何才能快速拓客呢？我们传统的拓客方式：发传单、在团购平台引流、老客户转介绍、靠位置优势自然获客。但这些方式现在能带来的效果都比较有限，现在想要快速拓客，还有一种方式，就是让你门店周边的异业商家不断的给你推荐优质客户，这种拓客速度最快的。如何才能让大家都愿意给你推客户呢？通过共享商圈就可以做到。共享商圈的意思就是你和周边的异业商家之间互相共享客户，同时共享利润。你把客户推荐到我的门店，我也把客户推荐到你店里，这样300个客户秒变成3000个，如果你的客户去对方店里消费了，你能分到这个客户消费产生的收益，他的客户到你店里消费也同样可以分到消费收益，这些推荐都跟利益挂钩，就能形成一个共享客户且持续增收的共赢模式。'
    }
  ]
}