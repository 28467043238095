







import { Component, Vue, Prop } from "vue-property-decorator"
import { pxTorem } from '../utils/utils'

@Component
export default class extends Vue {
  private pxTorem = pxTorem
  @Prop() text !: string
  @Prop() path !: string
  @Prop({
    default: 130
  }) width !: number
  @Prop({
    default: 40
  }) height !: number

}
