












import { Component, Vue } from 'vue-property-decorator';
import vFreeApply from '@/components/v-free-apply.vue';
import VBanner from '@/components/v-banner.vue';
import vCard from '@/components/v-card.vue'
import businessVs from './components/business-vs.vue'
import vNavButton from '@/components/v-nav-button.vue'
import businessData from '../../assets/data/business'

@Component({
  components: { vFreeApply,VBanner, vCard, businessVs, vNavButton }
})
export default class extends Vue {
  private bannerData = businessData.banner
  private cardsData = businessData.cards
}
